<template>
  <van-pull-refresh class="remindResponseRecord" v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad">

      <response-cell v-for="(item, index) in tableData" :data-info="item"></response-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import responseMixin from '@/views/shareAndDispatch/deviceView/response/responseMixin'
import ResponseCell from '@views/shareAndDispatch/deviceView/response/responseCell/responseCell'

export default {
  name: 'remindResponseRecord',
  components: { ResponseCell },
  mixins:[responseMixin],
  data() {
    return {
      queryType:7,
    }
  },
}
</script>

<style lang="scss" scoped>

.remindResponseRecord {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

</style>